import Vue from 'vue';
import Vuex from 'vuex';
import ZPanel from '@/components/base/panel/';
import ZTable from '@/components/base/table/';
import ZSelect from '@/components/base/z-select/';
import ZSwitch from '@/components/base/z-switch/';
import ZTag from '@/components/base/tag/';
import Btn from '@/components/base/btn/';
import Pagenation from '@/components/base/pagenation/';
import Modal from '@/components/base/modal/';
import { validatorsExp } from '@/assets/js/validate';

const { mapGetters, mapActions } = Vuex;

export default Vue.extend({
  name: 'AdminUser',
  components: {
    ZPanel,
    ZTable,
    ZSelect,
    ZSwitch,
    ZTag,
    Pagenation,
    Modal,
    Btn,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      pageTotal: 0,
      tableData: [],
      isLoading: false,
      isAddLoading: false,
      isEditLoading: false,
      isDeleteLoading: false,
      currentRow: {},
      isShowDeleteModal: false,
      isShowUserModal: false,
      editMode: 'add',
      formData: {},
      roleList: [],
    };
  },
  computed: {
    ...mapGetters('common', {
      userInfo: 'getUserInfo',
    }),
    roleNameObj() {
      const roleObj = {};
      this.roleList.forEach(item => {
        roleObj[item.id] = item.name;
      });
      return roleObj;
    },
    columns() {
      return [
        {
          title: '头像',
          key: 'poster',
          width: '80px',
          render: (h: any, parama: any) => {
            return h('img', {
              attrs: {
                src: parama.row.avatarUrl,
              },
              style: {
                width: '80px',
              },
            });
          },
        },
        {
          title: '姓名',
          key: 'full_name',
          align: 'left',
        },
        {
          title: 'ERP',
          key: 'user_name',
          align: 'left',
        },
        {
          title: '部门',
          key: 'department_name',
          align: 'left',
        },
        {
          title: '手机号',
          key: 'mobile',
        },
        {
          title: '邮箱',
          key: 'email',
          align: 'left',
        },
        {
          title: '创建时间',
          render: (h: any, parama: any) => {
            return h('div', this.$options.filters.dateFormatFilter(parama.row.createdAt, 'YYYY-MM-DD HH:mm'));
          },
        },
        {
          title: '修改时间',
          render: (h: any, parama: any) => {
            return h('div', this.$options.filters.dateFormatFilter(parama.row.updatedAt, 'YYYY-MM-DD HH:mm'));
          },
        },
        {
          name: '操作',
          render: (h: any, parama: any) => {
            return h('div', [
              h(
                Btn,
                {
                  props: {
                    theme: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.handleRowEdit(parama.row);
                    },
                  },
                },
                '设置角色'
              ),
              h(
                Btn,
                {
                  props: {
                    theme: 'error',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.handleRowDel(parama.row);
                    },
                  },
                },
                '删除'
              ),
            ]);
          },
        },
      ];
    },
  },
  mounted() {
    this.requestUserList();
    this.requestRoleList();
  },
  methods: {
    ...mapActions({
      toggleSignInModal: 'common/toggleSignInModal',
    }),

    /**
     * @desc 刷新
     */
    refresh() {
      this.requestUserList();
    },

    /**
     * @desc 请求 获取用户列表
     */
    requestUserList() {
      this.isLoading = true;
      const params = {
        page: this.page,
        pageSize: this.limit,
      };
      this.$myApi.users
        .index(params)
        .then((res: any) => {
          this.tableData = res?.data?.result || [];
          this.pageTotal = res?.data?.pageInfo?.total_pages || 0;
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 请求 获取角色列表
     */
    requestRoleList() {
      this.$myApi.role
        .getAllRole()
        .then((res: any) => {
          this.roleList = res.data;
        })
        .catch(err => {
          this.$toast.error(err?.data?.msg || err?.msg || '请求异常');
        });
    },

    /**
     * @desc 请求 新增标签
     */
    requestAddUser() {
      const params = {
        ...this.formData,
      };
      this.isAddLoading = true;
      this.$myApi.users
        .create(params)
        .then(() => {
          this.isAddLoading = false;
          this.$toast.success('添加成功！');
          this.handleHideUserModal();
          this.requestUserList();
        })
        .catch(() => {
          this.isAddLoading = false;
        });
    },

    /**
     * @desc 请求 修改文章标签
     */
    requestEditUser() {
      const params = {
        id: this.currentRow.id,
        role_id: this.formData.roles,
        is_enabled: this.formData.is_enabled,
      };
      this.isEditLoading = true;
      this.$myApi.users
        .update(params)
        .then(() => {
          this.isEditLoading = false;
          this.$toast.success('修改成功！');
          this.handleHideUserModal();
          this.requestUserList();
        })
        .catch(() => {
          this.isEditLoading = false;
        });
    },

    /**
     * @desc 请求 删除文章标签
     */
    requestDeleteUser() {
      this.isDeleteLoading = true;
      this.$myApi.users
        .delete(this.currentRow.id)
        .then(() => {
          this.isDeleteLoading = false;
          this.$toast.success('删除成功！');
          this.handleHideDeleteModal();
          this.requestUserList();
        })
        .catch(() => {
          this.isDeleteLoading = false;
        });
    },

    /**
     * @desc 分页点击
     */
    handleChangePage(page: number) {
      this.page = page;
      this.requestUserList();
    },

    /**
     * @desc 清空表单值
     */
    handleClearFormData() {
      this.formData = {};
    },

    /**
     * @desc 回显表单值
     */
    handleRecoveryFormData(data: any) {
      this.formData = {
        roles: data.roles.map(item => item.id),
        is_enabled: data.is_enabled,
        // department_id: "1"
        // department_name: "数据研发中心"
        // email: "test@jd.com"
        // full_name: "宋立辉"
        // id: 1
        // is_enabled: 1
        // mobile: "11111111111"
        // user_id: 1839568
        // user_name: "songlihui"
      };
    },

    /**
     * @desc 新增标签
     */
    handleAddUser() {
      this.editMode = 'add';
      this.handleShowUserModal();
    },

    /**
     * @desc 表格点击事件 编辑
     */
    handleRowEdit(row: any) {
      this.editMode = 'edit';
      this.currentRow = row;
      this.handleRecoveryFormData(row);
      this.handleShowUserModal();
    },

    /**
     * @desc 表格点击事件 删除
     */
    handleRowDel(row: any) {
      this.currentRow = row;
      this.handleShowDeleteModal();
    },

    /**
     * @desc 检查表单数据是否合格
     */
    handleCheckFormData() {
      const { roles, phone, email, password, confirmPassword } = this.formData;
      if (!roles || !roles.length) {
        this.$toast.error('请选择权限！');
        return false;
      }
      return true;
    },

    /**
     * @desc 验证是否已登录，是否为 admin 用户
     */
    handleValidateUserAuth() {
      let isUserAuth = false;
      if (this.userInfo) {
        if (this.userInfo.userName === 'admin') {
          isUserAuth = true;
        } else {
          this.$toast.error('非admin，无权限！');
        }
      } else {
        this.$toast.info('请登录');
        this.toggleSignInModal(true);
      }
      return isUserAuth;
    },

    /**
     * @desc 提交标签表单
     */
    handleSubmitUser() {
      if (!this.handleCheckFormData()) {
        return;
      }
      this.requestEditUser();
    },

    /**
     * @desc 显示标签表单弹框
     */
    handleShowUserModal() {
      this.isShowUserModal = true;
    },

    /**
     * @desc 隐藏标签表单弹框
     */
    handleHideUserModal() {
      this.isShowUserModal = false;
      this.handleClearFormData();
    },

    /**
     * @desc 显示删除标签弹框
     */
    handleShowDeleteModal() {
      this.isShowDeleteModal = true;
    },

    /**
     * @desc 隐藏删除标签弹框
     */
    handleHideDeleteModal() {
      this.isShowDeleteModal = false;
    },
  },
});
